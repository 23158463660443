<!-- System: STA
    Purpose: User can see his/her screenshots in workdiary.
-->
<template>
  <div>
    <!-- Headers Component -->
    <HeaderDropdowns />
  </div>
</template>

<script>
import $ from "jquery";
import { mapState } from "vuex";
export default {
  name: "work-diary",
  components: {
    HeaderDropdowns: () => import("@/components/workDiary/HeaderDropdowns"),
  },
  mounted() {
    this.getActiveTeamMembersList();
    $(".select2-selection__rendered").hover(function () {
      $(this).removeAttr("title").unbind("mouseenter mouseleave");
    });
  },
  computed: {
    ...mapState("custom", ["active_team_members_list"]),
  },
  methods: {
    /**
     * This function is resposible for fetching
     * team member list
     */
    getActiveTeamMembersList() {
      if (!this.active_team_members_list.length) {
        this.$store.dispatch("custom/get_active_team_members_list", {
          company_id: this.$route.params.id,
        });
      }
    },
  },
};
</script>

<style scoped>
</style>
